import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import ModalContext from '../../context/ModalContext'
import useWindowSize from '../../hooks/useWindowSize'

const MyModal = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 9999;

  ${(props) =>
    props.$active && props.$isContentReady &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

const MyModalContent = styled.div`
  background: white;
  min-width: 250px;
  background: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--line-color);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  padding: ${(props) => props.$padding[props.$isSmallScreen ? 'smallScreen' : 'largeScreen']};
  ${(props) =>
    props.$isSmallScreen
      ? css`
          border-radius: 8px;
        `
      : css`
          border-radius: 16px;
        `}
  ${(props) =>
    props.$isContentReady &&
    css`
    opacity: 1;
    visibility: visible;
  `}
`

export default function Modal({ children, modal, setModal, closeOnOutsideClick = true }) {
  const { modalPadding, setModalPadding } = useContext(ModalContext)
  const [content, setContent] = useState(null)
  const windowSize = useWindowSize()
  const modalRef = useRef(null)
  const modalContentRef = useRef(null)
  const [isContentReady, setContentReady] = useState(false)

  useLayoutEffect(() => {
    if (modal && content && modalContentRef.current && modalContentRef.current.offsetHeight > 0) {
      setContentReady(true)
    } else {
      setContentReady(false)
    }
  }, [modal, content, modalContentRef])

  useEffect(() => {
    if (modal) {
      setContent(children)
    } else {
      setTimeout(() => {
        setContent(null)
        setModalPadding({ smallScreen: '25px', largeScreen: '25px' })
      }, 300)
    }
  }, [modal, children, setModalPadding])

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        e.preventDefault()
        setModal(false)
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [setModal])

  return (
    <MyModal
      ref={modalRef}
      onClick={() => closeOnOutsideClick && setModal(false)}
      $active={modal}
      $isContentReady={isContentReady}
    >
      <MyModalContent
        ref={modalContentRef}
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
        $isSmallScreen={windowSize.isSmallScreen}
        $padding={modalPadding}
        $isContentReady={isContentReady}
      >
        {content}
      </MyModalContent>
    </MyModal>
  )
}