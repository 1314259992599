import PostService from '../../API/PostService'

// Action creator for setting the selected company
export const setCompany = (companyId) => {
  return {
    type: 'SET_COMPANY',
    payload: companyId
  }
}

//------- USERS -------//
export const fetchUsers = (language = 'ru') => async (dispatch) => {
  dispatch({ type: 'FETCH_USERS_REQUEST' })

  const response = await PostService.get('auth', 'users', {}, language)

  if (response && !response.errors) {
    dispatch({
      type: 'SET_USERS',
      payload: response.data,
    })
    dispatch({ type: 'FETCH_USERS_SUCCESS' })
  } else {
    dispatch({ type: 'FETCH_USERS_FAILURE' })
  }
}

//------- CARS -------//
export const fetchCars = (language = 'ru') => async (dispatch, getState) => {
  dispatch({ type: 'FETCH_CARS_REQUEST' })

  const { companyId } = getState().data

  const response = await PostService.get('data', `${companyId}/cars`, {}, language)

  if (response && !response.errors) {
    dispatch({
      type: 'SET_CARS',
      payload: response.data,
    })
    dispatch({ type: 'FETCH_CARS_SUCCESS' })
  } else {
    dispatch({ type: 'FETCH_CARS_FAILURE' })
  }
}

//------- HIRED CARS -------//
export const fetchHiredCars = (language = 'ru') => async (dispatch, getState) => {
  dispatch({ type: 'FETCH_HIRED_CARS_REQUEST' })

  const { companyId } = getState().data

  const response = await PostService.get('data', `${companyId}/hired-cars`, {}, language)

  if (response && !response.errors) {
    dispatch({
      type: 'SET_HIRED_CARS',
      payload: response.data,
    })
    dispatch({ type: 'FETCH_HIRED_CARS_SUCCESS' })
  } else {
    dispatch({ type: 'FETCH_HIRED_CARS_FAILURE' })
  }
}

//------- HIRED DRIVERS -------//
export const fetchHiredDrivers = (language = 'ru') => async (dispatch, getState) => {
  dispatch({ type: 'FETCH_HIRED_DRIVERS_REQUEST' })

  const { companyId } = getState().data

  const response = await PostService.get('data', `${companyId}/hired-drivers`, {}, language)

  if (response && !response.errors) {
    dispatch({
      type: 'SET_HIRED_DRIVERS',
      payload: response.data,
    })
    dispatch({ type: 'FETCH_HIRED_DRIVERS_SUCCESS' })
  } else {
    dispatch({ type: 'FETCH_HIRED_DRIVERS_FAILURE' })
  }
}

//------- CUSTOMER -------//
export const fetchCustomers = (language = 'ru') => async (dispatch, getState) => {
  dispatch({ type: 'FETCH_CUSTOMERS_REQUEST' })

  const { companyId } = getState().data
  const response = await PostService.get('data', `${companyId}/customers`, {}, language)

  if (response && !response.errors) {
    dispatch({
      type: 'SET_CUSTOMERS',
      payload: response.data
    })
    dispatch({ type: 'FETCH_CUSTOMERS_SUCCESS' })
  } else {
    dispatch({ type: 'FETCH_CUSTOMERS_FAILURE' })
  }
}

//------- CARRIERS -------//
export const fetchCarriers = (language = 'ru') => async (dispatch, getState) => {
  dispatch({ type: 'FETCH_CARRIERS_REQUEST' })

  const { companyId } = getState().data
  const response = await PostService.get('data', `${companyId}/carriers`, {}, language)

  if (response && !response.errors) {
    dispatch({
      type: 'SET_CARRIERS',
      payload: response.data
    })
    dispatch({ type: 'FETCH_CARRIERS_SUCCESS' })
  } else {
    dispatch({ type: 'FETCH_CARRIERS_FAILURE' })
  }
}

//------- EMPLOYEES -------//
export const fetchEmployees = (language = 'ru') => async (dispatch, getState) => {
  dispatch({ type: 'FETCH_EMPLOYEES_REQUEST' })

  const { companyId } = getState().data
  const response = await PostService.get('data', `${companyId}/employees`, {}, language)

  if (response && !response.errors) {
    dispatch({
      type: 'SET_EMPLOYEES',
      payload: response.data,
    })
    dispatch({ type: 'FETCH_EMPLOYEES_SUCCESS' })
  } else {
    dispatch({ type: 'FETCH_EMPLOYEES_FAILURE' })
  }
}

//------- EMPLOYEES SALARIES -------//
export const fetchEmployeesSalaries = (language = 'ru') => async (dispatch, getState) => {
  dispatch({ type: 'FETCH_EMPLOYEES_SALARIES_REQUEST' })

  const { companyId } = getState().data
  const response = await PostService.get('data', `${companyId}/employees-salaries`, {}, language)

  if (response && !response.errors) {
    dispatch({
      type: 'SET_EMPLOYEES_SALARIES',
      payload: response.data,
    })
    dispatch({ type: 'FETCH_EMPLOYEES_SALARIES_SUCCESS' })
  } else {
    dispatch({ type: 'FETCH_EMPLOYEES_SALARIES_FAILURE' })
  }
}

//------- DRIVERS -------//
export const fetchDrivers = (language = 'ru') => async (dispatch, getState) => {
  dispatch({ type: 'FETCH_DRIVERS_REQUEST' })

  const { companyId } = getState().data
  const response = await PostService.get('data', `${companyId}/drivers`, {}, language)

  if (response && !response.errors) {
    dispatch({
      type: 'SET_DRIVERS',
      payload: response.data,
    })
    dispatch({ type: 'FETCH_DRIVERS_SUCCESS' })
  } else {
    dispatch({ type: 'FETCH_DRIVERS_FAILURE' })
  }
}

//------- SUPPLIERS -------//
export const fetchSuppliers = (language = 'ru') => async (dispatch, getState) => {
  dispatch({ type: 'FETCH_SUPPLIERS_REQUEST' })

  const { companyId } = getState().data
  const response = await PostService.get('data', `${companyId}/suppliers`, {}, language)

  if (response && !response.errors) {
    dispatch({
      type: 'SET_SUPPLIERS',
      payload: response.data,
    })
    dispatch({ type: 'FETCH_SUPPLIERS_SUCCESS' })
  } else {
    dispatch({ type: 'FETCH_SUPPLIERS_FAILURE' })
  }
}

//------- EXPENSES -------//
export const fetchExpenses = (language = 'ru') => async (dispatch, getState) => {
  dispatch({ type: 'FETCH_EXPENSES_REQUEST' })

  const { companyId } = getState().data
  const response = await PostService.get('data', `${companyId}/expenses`, {}, language)

  if (response && !response.errors) {
    dispatch({
      type: 'SET_EXPENSES',
      payload: response.data,
    })
    dispatch({ type: 'FETCH_EXPENSES_SUCCESS' })
  } else {
    dispatch({ type: 'FETCH_EXPENSES_FAILURE' })
  }
}

//------- REFUELING -------//
export const fetchRefueling = (language = 'ru') => async (dispatch, getState) => {
  dispatch({ type: 'FETCH_REFUELING_REQUEST' })

  const { companyId } = getState().data
  const response = await PostService.get('data', `${companyId}/refueling`, {}, language)

  if (response && !response.errors) {
    dispatch({
      type: 'SET_REFUELING',
      payload: response.data,
    })
    dispatch({ type: 'FETCH_REFUELING_SUCCESS' })
  } else {
    dispatch({ type: 'FETCH_REFUELING_FAILURE' })
  }
}

//------- MAINTENANCE -------//
export const fetchMaintenance = (language = 'ru') => async (dispatch, getState) => {
  dispatch({ type: 'FETCH_MAINTENANCE_REQUEST' })

  const { companyId } = getState().data
  const response = await PostService.get('data', `${companyId}/maintenance`, {}, language)

  if (response && !response.errors) {
    dispatch({
      type: 'SET_MAINTENANCE',
      payload: response.data,
    })
    dispatch({ type: 'FETCH_MAINTENANCE_SUCCESS' })
  } else {
    dispatch({ type: 'FETCH_MAINTENANCE_FAILURE' })
  }
}

//------- INVENTORY -------//
export const fetchInventory = (language = 'ru') => async (dispatch, getState) => {
  dispatch({ type: 'FETCH_INVENTORY_REQUEST' })

  const { companyId } = getState().data
  const response = await PostService.get('data', `${companyId}/inventory`, {}, language)

  if (response && !response.errors) {
    dispatch({
      type: 'SET_INVENTORY',
      payload: response.data,
    })
    dispatch({ type: 'FETCH_INVENTORY_SUCCESS' })
  } else {
    dispatch({ type: 'FETCH_INVENTORY_FAILURE' })
  }
}

//------- STOCK -------//
export const fetchStock = (language = 'ru') => async (dispatch, getState) => {
  dispatch({ type: 'FETCH_STOCK_REQUEST' })

  const { companyId } = getState().data
  const response = await PostService.get('data', `${companyId}/stock`, {}, language)

  if (response && !response.errors) {
    dispatch({
      type: 'SET_STOCK',
      payload: response.data,
    })
    dispatch({ type: 'FETCH_STOCK_SUCCESS' })
  } else {
    dispatch({ type: 'FETCH_STOCK_FAILURE' })
  }
}

//------- ROUTES -------//
export const fetchRoutes = (language = 'ru') => async (dispatch, getState) => {
  dispatch({ type: 'FETCH_ROUTES_REQUEST' })

  const { companyId } = getState().data
  const response = await PostService.get('data', `${companyId}/routes`, {}, language)

  if (response && !response.errors) {
    dispatch({
      type: 'SET_ROUTES',
      payload: response.data
    })
    dispatch({ type: 'FETCH_ROUTES_SUCCESS' })
  } else {
    dispatch({ type: 'FETCH_ROUTES_FAILURE' })
  }
}

//------- RESET DATA STATE -------//
export const resetDataState = () => {
  return {
    type: 'RESET_DATA_STATE'
  }
}

