import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from '../../../hooks/useAuth'
import { useSoundTheme } from '../../../hooks/useSoundTheme'

const NavWrapper = styled.div`
  background-color: transparent;
  line-height: normal;
`

const Tabs = styled.ul`
  background-color: transparent;
  top: 0;
  height: 48px;
  width: 100%;
  white-space: nowrap;
  display: flex;
  flex-shrink: 0;
  & > li:first-child {
    margin-left: 15px;
  }
`

const Tab = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 0 15px;
  text-transform: uppercase;
  cursor: pointer;

  &.active {
    background-color: var(--surface-color-lighten);
    transition: background-color .28s ease;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #c6d0d5;
    transition: color .3s;
    -webkit-tap-highlight-color: transparent;
    height: 100%;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;

    &:hover,
    &:active {
      text-decoration: none;
      color: #fff;
    }
  }

  &.active a {
    color: #fff;
  }
`

const NavigationTab = ({ label, to, isActive, onClick, backgroundColor }) => (
  <Tab className={isActive ? 'tab active' : 'tab'} $backgroundColor={backgroundColor}>
    <NavLink to={to} onClick={onClick}>{label}</NavLink>
  </Tab>
)

export default function Navigation() {
  const [activeTab, setActiveTab] = useState('')
  const { companyId } = useSelector(state => state.data)
  const { roles } = useAuth()
  const { tabSound } = useSoundTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  function roleCheck(item) {
    // Allow all users to access the 'Home' tab
    if (item === 'home') {
      return true
    }

    return roles.some(role => role.value === 'admin') ||
      roles.some(role => role.value === item && (role.companyId ? role.companyId === companyId : false))
  }

  const tabs = [
    { label: 'Главная', to: '/', access: 'home' },
    { label: 'Реестр маршрутов', to: '/routes', access: 'routes' },
    { label: 'Расходы', to: '/expenses', access: 'expenses' },
    { label: 'Топливо', to: '/refueling', access: 'refueling' },
    { label: 'Т.О. Ремонт', to: '/maintenance', access: 'maintenance' },
    { label: 'Склад', to: '/stock', access: 'stock' },
    { label: 'Номенклатура', to: '/inventory', access: 'inventory' },
    { label: 'Зарплата водителей', to: '/drivers-salary', access: 'drivers-salary' },
    { label: 'Зарплата сотрудников', to: '/employees-salaries', access: 'employees-salaries' },
    { label: 'Прибыль', to: '/profit', access: 'profit' },
    { label: 'Сотрудники', to: '/employees', access: 'employees' },
    { label: 'Водители', to: '/drivers', access: 'drivers' },
    { label: 'Заказчики', to: '/customers', access: 'customers' },
    { label: 'Автомобили', to: '/cars', access: 'cars' },
    { label: 'Наемный транспорт', to: '/hired-cars', access: 'hired-cars' },
    { label: 'Наемные водители', to: '/hired-drivers', access: 'hired-drivers' },
    { label: 'Перевозчики', to: '/carriers', access: 'carriers' },
    { label: 'Поставщики', to: '/suppliers', access: 'suppliers' },
  ].filter(tab => roleCheck(tab.access))

  const handleTabClick = useCallback((to) => {
    tabSound()
    navigate(to)
  }, [tabSound, navigate])

  useEffect(() => {
    // Get the current path
    const currentPath = location.pathname

    // Extract the base path (first segment of the URL path)
    const basePath = currentPath.split('/')[1] // splits the path and takes the first segment

    // Set the active tab and dispatch action
    setActiveTab('/' + basePath)
    dispatch({ type: 'SET_ACTIVE_TAB_ID', payload: '/' + basePath })
  }, [location, dispatch])

  return (
    <NavWrapper>
      <Tabs>
        {tabs.map(tab => (
          <NavigationTab
            key={tab.to}
            label={tab.label}
            to={tab.to}
            isActive={activeTab === tab.to}
            onClick={() => handleTabClick(tab.to)}
          />
        ))}
      </Tabs>
    </NavWrapper>
  )
}