import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import Library from '../../Library'
import LinearLoader from '../../components/Loaders/LinearLoader'
import Footer from '../../components/Footer'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import ListScrollAllSides from '../../components/ListScrollAllSides'
import Button from '../../components/Buttons/Button'
import moment from 'moment'
import ResultInput from '../../components/Inputs/ResultInput'
import VirtualizedList from '../../components/VirtualizedLists/VirtualizedList'
import useTranslate from '../../hooks/useTranslate'
import styled from 'styled-components'
import FilterButton from '../../components/ListFilter/components/FilterButton'
import { ShowPayments } from './components/ShowPayments/ShowPayments'
import ModalContext from '../../context/ModalContext'

const ResultInputWrapper = styled.div`
  margin-left: 10px;
  min-width: 115px;
  max-width: 115px;
`

export const Main = () => {
  const location = useLocation()
  const pageName = location.pathname.substring(1)
  const translate = useTranslate()
  const router = useNavigate()
  const virtualizedListRefs = {
    listRef: useRef(),
    headerRef: useRef()
  }
  const isDataLoaded = useSelector((state) => state.loading.isDataLoaded)
  const mainState = useSelector(state => state.main)
  const pageState = useSelector(state => state.page[pageName])
  const [filterQuery, setFilterQuery] = useState(pageState.filterQuery)
  const [totalAmount, setTotalAmount] = useState(0)
  const [dataReady, setDataReady] = useState(false)
  const { showModal, setModalContent, setModalPadding, setCloseOnOutsideClick } = useContext(ModalContext)

  useEffect(() => {
    setFilterQuery(pageState.filterQuery)
  }, [pageState.filterQuery])

  const headers = useMemo(() => [
    { id: 'id', name: 'id', width: 275, hidden: true },
    { id: 'timestamp', name: 'timestamp', dataType: 'date', type: 'timestamp', width: 165, hidden: true },
    { id: 'number', name: '№', dataType: 'number', type: 'increment', width: 70 },
    { id: 'status', name: 'Статус', type: 'status', width: 60 },
    { id: 'date', name: 'Дата', dataType: 'date', type: 'date', width: 100 },
    { id: 'employee', name: 'Сотрудник', width: 250 },
    { id: 'period', name: 'Период', width: 100 },
    { id: 'amount', name: 'Сумма', dataType: 'number', width: 100 },
    { id: 'comment', name: 'Комментарий', width: 355 },
    { id: 'lastModified', name: 'Дата изменения', dataType: 'date', type: 'timestamp', width: 140 },
  ].map(header => {
    if (!header.width) {
      header.width = 100
    }
    if (!header.dataType) {
      header.dataType = 'string'
    }
    return header
  }), [])

  const { employees, employeesSalaries, expenses, loading, error } = useSelector(state => state.data)
  const [employeesSalariesReplaced, setEmployeesSalariesReplaced] = useState([])

  const filteredTableData = useMemo(() => {
    const { search, status, startDate, endDate } = filterQuery

    const matchesSearch = (item) =>
      !search || Object.values(item)
        .toString()
        .toLowerCase()
        .includes(search.toLowerCase())

    const matchesStatus = (item) =>
      !status || item.status === status

    const matchesStartDate = (item) =>
      !startDate || moment(item.date).isSameOrAfter(startDate, 'day')

    const matchesEndDate = (item) =>
      !endDate || moment(item.date).isSameOrBefore(endDate, 'day')

    const filtered = employeesSalariesReplaced.filter(
      (item) =>
        matchesSearch(item) &&
        matchesStatus(item) &&
        matchesStartDate(item) &&
        matchesEndDate(item)
    )

    pageState.sort.key && Library.sort(filtered, pageState.sort)

    return filtered
  }, [employeesSalariesReplaced, filterQuery, pageState.sort])

  useEffect(() => {
    Library.activeTabScroll(mainState)
  }, [employeesSalariesReplaced, mainState])

  //======NEW ENTRY======//
  const newEntry = useCallback(() => {
    router(`/${pageName}/new`)
  }, [router, pageName])

  useEffect(() => {
    if (!isDataLoaded || loading || error) return
    setDataReady(true)
  }, [isDataLoaded, loading, error])

  useEffect(() => {
    if (!dataReady || loading || error) {
      return
    }
    let replaced = []
    employeesSalaries.forEach((originalItem) => {
      let item = { ...originalItem }
      item['employee'] = Library.findNameByKey(employees, item.employee)
      if (item.period) {
        item['period'] = Library.replacePeriod(item.period)
      }
      replaced.push(item)
    })
    setEmployeesSalariesReplaced(replaced)
  }, [dataReady, error, loading, employees, employeesSalaries])

  useEffect(() => {
    let val = 0
    filteredTableData.forEach((item) => {
      val += item.amount || 0
    })
    setTotalAmount(val)
  }, [filteredTableData])

  return (
    <>
      <LinearLoader
        isLoading={!dataReady || !isDataLoaded}
        style={{ height: '4px' }}
      />
      {dataReady && isDataLoaded &&
        <>
          <VirtualizedList
            ref={virtualizedListRefs}
            offsetHeight={140}
            headers={headers}
            pageName={pageName}
            items={filteredTableData}
            filterQuery={filterQuery}
            separateDateKey={pageState.sort.dataType === 'date' && pageState.sort.key}
            navigateTo={pageName}
          />
          <Footer>
            <ListScrollAllSides virtualizedListRefs={virtualizedListRefs} />
            <Button
              onClick={newEntry}
              title={translate('newEntry')}
            >
              {translate('newEntry')}
            </Button>
            {/* ------- FILTER BUTTON ------- */}
            <FilterButton
              filterQuery={filterQuery}
              setFilterQuery={setFilterQuery}
              pageName={pageName}
            />
            <Button
              style={{ minWidth: '110px' }}
              onClick={(e) => {
                e.preventDefault()
                setModalContent(<ShowPayments
                  employees={employees}
                  employeesSalaries={employeesSalaries}
                  expenses={expenses}
                />)
                setModalPadding({ smallScreen: '30px 5px 20px 5px;', largeScreen: '25px' })
                setCloseOnOutsideClick(false)
                showModal(true)
              }}
              title='Показать выплаты'
            >
              ВЫПЛАТЫ
            </Button>
            {/* ------- TOTAL COST ------- */}
            <ResultInputWrapper>
              <ResultInput
                value={`${totalAmount?.toLocaleString()} Руб.`}
                forceDarkMode={true}
                borderOption={3}
              />
            </ResultInputWrapper>
          </Footer>
        </>
      }
    </>
  )
}